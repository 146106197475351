import React from 'react'
import { Row, Col } from 'reactstrap'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import IconLink from '../common/icon-link'

const MemberContainer = styled.div`
  background: #f8f9fa;
  padding: 2rem;
  margin: 2rem 0;
`

const Member = ({ name, title, image, linkedin, twitter, github, website, children }) => (
  <MemberContainer>
    <Row>
      <Col md={4}>
        <img className="rounded-circle p-4" src={image} width="100%" />
      </Col>
      <Col>
        <h4>{name}</h4>
        <h5>{title}</h5>
        <div className="mt-3">
          {children}
        </div>
      </Col>
    </Row>
    <div className="d-flex justify-content-center mt-4">
      {linkedin && <IconLink href={linkedin} icon={['fab', 'linkedin']} target="_blank" />}
      {twitter && <IconLink href={twitter} icon={['fab', 'twitter']} target="_blank" />}
      {github && <IconLink href={github} icon={['fab', 'github']} target="_blank" />}
      {website && <IconLink href={website} icon={['fal', 'globe']} target="_blank" />}
    </div>
  </MemberContainer>
)

export default Member