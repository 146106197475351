import React from "react"
import styled from 'styled-components'
import { graphql } from 'gatsby'

import Layout from '../layout'

import BackgroundContainer from '../components/image/background-container'
import TitleContainer from '../components/common/title-container'
import Title from '../components/common/title'
import Subtitle from '../components/common/subtitle'
import PageContainer from '../components/common/page-container'
import Link from '../components/common/link'
import DiscreetLink from '../components/common/discreet-link'

import Member from '../components/about/member'

import NathanImage from '../assets/images/about/nathan.jpg'
import MichaelImage from '../assets/images/about/michael.jpg'
import CertifiedImage from '../assets/images/about/aws-certified.png'

export default (props) => (
  <Layout title="About Us">
    <BackgroundContainer header={props.data.header}>
      <TitleContainer>
        <Title>About Us</Title>
        <Subtitle>The team to trust with your software project</Subtitle>
      </TitleContainer>
    </BackgroundContainer>

    <PageContainer>
      <p>We are an experienced team of technology professionals, who specialise in building online systems for businesses. This ranges from full software-as-a-service builds, APIs, back-office systems and technical consultancy. As evidence of our
        expertise, <Link href="/about/events">we regularly present at technical conferences and user groups</Link>, <Link href="/about/publications">write books on software development</Link> and <Link href="/about/certifications" title="Our certifications">hold a number of industry certifications</Link>.
      </p>
      <Member
        name="Michael Peacock"
        title="Technical Director"
        image={MichaelImage}
        twitter="https://twitter.com/michaelpeacock"
        linkedin="https://www.linkedin.com/in/michaelkeithpeacock/"
        github="https://github.com/mkpeacock"
      >

        <p>
          Michael is an experienced <DiscreetLink href="https://michaelpeacock.co.uk" title="Michael Peacock, Experienced CTO">Chief Technology Officer</DiscreetLink> who provides both high level technical leadership, and hands on development services to clients.
        </p>

        <img className="p-4 float-right" style={{width: 200}} src={CertifiedImage} width="100%" />

        <p>As Technical Director of packagedby, Michael architects solutions for our clients, and ensures development of robust, scalable, and well tested systems. An AWS Certified Solutions Architect, Michael leverages Amazon Web Services to reliably host projects that scale.</p>

        <p>Michael has spoken at a number of conferences and user-groups, including ConFoo in Montreal, Cloud Connect in Santa Clara, CA, DPC in Amsterdam and PHP UK Conference in London. Since 2014, he has organised the PHP North East user group.</p>

        <p>Over the years, Michael has has 8 technical books published, and served as the technical reviewer for a range of other technical books.</p>
      </Member>
      <Member
        name="Nathan Dunn"
        title="Innovation Director"
        image={NathanImage}
        linkedin="https://www.linkedin.com/in/nathanjdunn/"
        twitter="https://twitter.com/nathanjdunn"
        github="https://github.com/nathanjdunn"
      >
        <p>Nathan is an experienced Software Development Consultant with over five years of industry experience, working with small to medium sized businesses and delivering projects for large enterprises. Nathan specialises in developing Software as a Service systems, building advanced APIs for use with web and mobile applications, and full-stack web application development.</p>
        <p>As Innovation Director at packagedby, Nathan finds innovative solutions to client challenges. Where necessary, putting forward the case for buy-vs-build when existing services solve problems for clients which don't impact on their core business.</p>
      </Member>
    </PageContainer>
  </Layout >
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "about.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
